import React, { useState } from "react";
import Main from "./Main";

const App = () => {
  // Hardcoded credentials
  const validUsername = "voicebite";
  const validPassword = "V0icebitten!";

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = () => {
    if (username === validUsername && password === validPassword) {
      setIsAuthenticated(true);
      setError(""); // Clear any previous errors
    } else {
      setError("Invalid credentials. Please try again.");
    }
  };
  console.log(error);

  return (
    <div>
      {isAuthenticated ? (
        <Main />
      ) : (
        <div>
          <h1>Login</h1>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <input
            type="text"
            name="username"
            autocomplete="username"
            className="input"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            name="password"
            autocomplete="password"
            className="input password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="button" onClick={handleLogin}>Login</button>
        </div>
      )}
    </div>
  );
};

export default App;
