import React from 'react';
import './OrderSummary.css'; // Optional CSS for styling

const OrderSummary = ({ items }) => {
  const formatPrice = (price) => `$${(price / 100).toFixed(2)}`; // Convert cents to dollars

  return (
    <div className="order-summary">
      {items.map((item) => (
        <div key={item.id} className="order-item">
          <div className="order-item-header">
            <div className="order-item-quantity">{item.quantity}x</div>
            <div className="order-item-name">{item.name.split('##')[0]}</div>
            <div className="order-item-price">{formatPrice(item.price)}</div>
          </div>
          {item.modifiers.length > 0 && (
            <ul className="order-item-modifiers">
              {item.modifiers.map((modifier, index) => (
                <li key={index} className="order-item-modifier">
                  <span className="modifier-name">{modifier.name}</span>
                  {modifier.price > 0 && (
                    <span className="modifier-price">${(modifier.price / 100).toFixed(2)}</span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default OrderSummary;
