//import './AudioContextMonkeyPatch.js'
import { getConfig } from './config.js'

class AudioWithAwp{
    //Mic data => comes via inputBuffer -> put in audioMicQ (transforms it to proper sampling rate)->send to srvr on ws
    //Spkr data => to be put in outputBuffer -> get it from audioSpkrQ, if empty otherwise send silence
    constructor(stream, hostWs, sampleRate, handleCartData){
        this.stream = stream   //stream is required to extract the microphone input
        this.hostWs = hostWs   //required to send mic output back to host
        this.sampleRate = sampleRate;
        this.handleCartData = handleCartData;
        this.awpStatus = 'running';  //The processor is stopped, and can be started with 'Hello' on the port (not implemented fully)

        this.hostWs.onmessage = function(evt) {
            console.log(`Received from host: ${evt.data}`);
            //console.log("WebSocket message received and put in audioSpeakerQ:", evt);
            this.sendDataToAwp(evt.data) //async function
        }.bind(this);
      }
    
      async sendDataToAwp(d) {
        if (this.awpStatus === 'stopped') {
            console.log(`Msg to AudioWorklet is ignored..AudioWorklet is in stopped state.`);
            return; // Exit early if the processor is stopped
        }

        if (typeof d === 'string') {
            try {
                const cart_data = JSON.parse(d);
                console.log('[nexmoRtc.AudioWithAwp.sendDataToAwp] cart_data', cart_data);
                if (cart_data.event && cart_data.event.includes('stt-result')){
                    console.log('[nexmoRtc.AudioWithAwp.sendDataToAwp] stt-result', cart_data);
                    this.handleCartData(cart_data);
                }else {
                    console.log('[nexmoRtc.AudioWithAwp.sendDataToAwp] cart_data', cart_data);
                    this.handleCartData(cart_data);
                }
            } catch (error) {
                console.error('[nexmoRtc.AudioWithAwp.sendDataToAwp] Error parsing JSON:', error);
            }
            // this.awPPort.postMessage(d);
        } else if (d instanceof ArrayBuffer || ArrayBuffer.isView(d)) {
            //console.log('Sending binary data to AudioWorklet:', d);
            this.awPPort.postMessage(d);
        } else {
            console.warn('Unsupported data type:', typeof d);
        }
    }
    
    recvDataFromAwp() {
        return new Promise(function(resolve, reject) {
            this.awPPort.onmessage = function(evt) {
                resolve(evt.data);
            }
        }.bind(this))
    }
    
    async sendStopToAwp(restartFlag) {
        //requests awp to stop
        if (this.awN){
            this.restartFlag = restartFlag
            this.awN.disconnect()
            this.awPPort.postMessage('stop') //send stop to the Awp
            this.awN=null
        } else console.log('Waiting for awp to stop calling back after death!')
    }

    async awpRecieveLoop (evt){
        let msgFromAwp = evt.data
        //console.log(`Received from micSpkrAwp: ${msgFromAwp}`);
        let tMsg = typeof(msgFromAwp)
        if (!(tMsg === 'string')) {//audio data
            
            if (this.hostWs.readyState === this.hostWs.OPEN)
            { await this.hostWs.send(msgFromAwp)
            //console.log(`sending audio data to host..${tMsg}, Length=${msgFromAwp.length}`)
            }
            else {
            //backend host is not alive so stop processsor
            console.log(`backend host websocket is not open: ${this.hostWs.readyState}. AWP stopped`)
            await this.sendStopToAwp(false) //send stop to the Awp
            }
        }
        else { //process the message
            if (msgFromAwp === 'stopped') {
                this.awpStatus='stopped'
                console.log(`Received "${msgFromAwp}" from Awp`)
                this.awN.disconnect()
            }
            else {
            console.log(`Error: unknow msg: ${msgFromAwp} received from Awp!`)
            }
        }
    }
    
    //***Start the audio Worklet with the micSpkrAwp processor */
    static async factory(stream,hostWs,sampleRate, emulator) {
        /* 
        *Right Side*: The audio part with microphone/speaker
        The audionode diagram:
        1. create an audioContext. This defines sample rate, worklet processor etc. and is the background for
           all audioNodes that communicate in this context
        2. Mic->stream->audioContext.createMediaStreamSource->MediaStreamAudioSourceNode (AudioNode)
        #AudioNode can connect to another AudioNode
        3. Create a AudioWorlketNode which is an AudioNode with an added port for communication the left side
        
        *Left Side*: The audio from AI to send to Right Side (which goes to the speaker), and receives 
        microphone audio from the right side. These are done through a port.
        1. Get the port for communication to the Right-side (AudioWorklet node).
        2. Make an handshake to the AudioWorkletNode port (send 'Hello' and receive 'requestToSend')
        3. Add listener to the port (get the microphone audio)
        4. Create a function to send to the port (called to send AI audio to right side)
        
        *connect the Audio nodes*
        1. connect the nodes. microphone -> AudioWorklet -> audioContext destination (speaker)

        */
        const aw=new AudioWithAwp(stream,hostWs,sampleRate, emulator)
        aw.audioContext = new AudioContext({sampleRate: sampleRate})  //This also sets aproximately how often the audio-worklet-processor is called
        const pName='micSpkrAwp.js'
        
        try {
            aw.awP = await aw.audioContext.audioWorklet.addModule(pName)
        } catch (e) {
            console.log(`could not add processor ${pName} to audioworklet`)
            throw e;
        }
        // 2. Mic->stream->audioContext.createMediaStreamSource->MediaStreamAudioSourceNode (AudioNode)
        aw.microphone = aw.audioContext.createMediaStreamSource(stream) //MediaStreamAudioSourceNode
        // 3. Create a AudioWorlketNode which is an AudioNode with an added port for communication the left side
        aw.awN=new AudioWorkletNode(aw.audioContext, 'mic-spkr-processor',{'processorOptions':{'config':getConfig(aw.sampleRate)}}) 
        // *Left side*
        // 1. Get the port for communication to the Right-side (AudioWorklet node).
        aw.awPPort = aw.awN.port
        //2. Make an handshake to the AudioWorkletNode port (send 'Hello' and receive 'requestToSend')
        aw.awPPort.postMessage('Hello')
        let msg = await aw.recvDataFromAwp()  //indicates that the processor has started
        console.log(`Received from Awp: ${msg}`)

        // 2. Add listener to the port (get the microphone audio)
        // 4. connect the nodes. microphone -> AudioWorklet -> audioContext destination (speaker)
        aw.microphone.connect(aw.awN)
        aw.awN.connect(aw.audioContext.destination) //connect this to the destination (spkr)
        aw.awPPort.addEventListener("message", aw.awpRecieveLoop.bind(aw));
        console.log('aw created..')
        return aw
    }
}

export {AudioWithAwp};