//This config file should come from configurator.
//Note: For now the mic (qParamsFromBrowserToSrvr) has to have a outChunksize of 160. It 
//should work for 10,20,30 ms (20ms @8000 samples = 160) and 160 is compatible with nexmo
function getConfig(samplingRate) {
  //change config as per samplingRate of 8000 or 16000
  let chunkSizeFor20ms = Math.round(samplingRate*0.02)
  let config =  {
      'audioConstraints':{sampleSize:8,channelCount:1,sampleRate:samplingRate,echoCancellation:true}
      ,'qParamsFromSrvrToBrowser':{name: 'toSpkrQ', outChunkType:'Float32', qSizeInSeconds:20
        , inSampleRate: samplingRate,outSampleRate: samplingRate, outChunkSize: 128}  //128 works in chrome. Larger values may be allowed in future
      ,'qParamsFromBrowserToSrvr':{name: 'fromMicQ',outChunkType:'Int16', qSizeInSeconds:20
        , inSampleRate: samplingRate,outSampleRate: samplingRate, outChunkSize: chunkSizeFor20ms} //keep at 20ms so that webrtcVad works 
      ,'timeOut':500
      ,'outOfBandSignal': 0b01010101
    }
  return config
}

export {getConfig}