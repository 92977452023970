import React, { useState, useEffect, useRef } from 'react';
import './DialogOverlay.css'; // CSS file for styling

const DialogOverlay = ({ dialogState, lastText }) => {
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Filter only ACTIVE messages from the dialog state
    const activeMessages = dialogState.filter((message) => message.status === 'ACTIVE');
    setMessages(activeMessages);
  }, [dialogState]);

  useEffect(() => {
    // Scroll to the bottom whenever messages update
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="dialog-overlay">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`dialog-bubble ${message.typ === 'AI' || message.typ === 'OAI' ? 'ai-bubble' : 'stt-bubble'}`}
        >
          <div className="dialog-header">{message.typ === 'AI' || message.typ === 'OAI' ? 'Crew is speaking' : 'Listening'}</div>
          <div className="dialog-text">{message.text}</div>
        </div>
      ))}
      {/* This element is used as the target for scrolling */}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default DialogOverlay;
